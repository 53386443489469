import React from 'react'
import logo from '../homeLogo.png'

// import { Button } from 'react-bootstrap'

// import FeatureGroup from '../components/FeatureGroup'
// import Todos from '../ui-components/Todos'
import './style.css'

const Home = (props) => {
  return (
    <div className='page-container'>
      <div className='home-logo'>
        <img src={logo} className='app-logo ' alt='logo' />
      </div>
      <h2 className='page-content-header'>Site under construction</h2>
      <div className='page-content'></div>
      {/* <Button className='quote-btn'>Free Quote</Button> */}
    </div>
  )
}

export default Home
