import React from 'react'

import './style.css'

const Footer = () => {
  return (
    <div className='footer'>
      <div>© 2022 innovathorn</div>
    </div>
  )
}

export default Footer
