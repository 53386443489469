import './App.css'

// import Navigation from './ui-components/Navigation'
import Home from './pages/Home'
import Footer from './ui-components/Footer'

function App() {
  return (
    <div className='App'>
      {/* <Navigation/> */}
      <Home />
      <Footer />
    </div>
  )
}

export default App
